/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.com/docs/gatsby-config/
 */

module.exports = {
  siteMetadata: {
    title: `Simplity`,
    description: `Simplity`,
    siteUrl: `https://www.simplity.ai`,
    author: `Simplity`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-sass`,
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /icons/,
        },
      },
    },
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "xPdmkbEYuKfJFQMiI0OOVgtt",
        version: "draft",
        homeSlug: "home",
        resolveLinks: "url",
        resolveRelations: [
          "logos",
          "resource",
          "Testimonial Slider.testimonials",
          "ctaBanner",
          "form",
          "cards",
          "caseStudy",
          "resources",
          "servicesCards",
          "servicesList",
          "dataAssessmentCards",
          "dataCurationCards",
          "analyticsCards",
          "recognitions",
          "blogAuthor",
          "blogCards",
          "caseStudiesCards",
          "reportsCards",
          "webinarsCards",
        ],
      },
    },
    {
      resolve: "gatsby-plugin-cookiebot",
      options: {
        cookiebotId: "96186067-f75f-47a5-af95-741779eae0be", // Required. Site's Cookiebot ID.
        manualMode: false, // Optional. Turns on Cookiebot's manual mode. Defaults to false.
        blockGtm: true, //  Optional. Skip blocking of GTM. Defaults to true if manualMode is set to true.
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#0C2340`,
        theme_color: `#0C2340`,
        display: `minimal-ui`,
        icon: `src/assets/images/favicon.svg`,
      },
    },
    {
      resolve: "gatsby-plugin-sitemap",
      options: {
        exclude: [
          "/services-cards/*",
          "/blog-author-bios/*",
          "/case-studies/*",
          "/testimonials/*",
          "/cta-banners/*",
          "/reusable-forms/*",
          "/whitepapers/*",
          "/recognitions-logos/*",
          "/cookie-consent-popup/",
          "/employee-cards/*",
          "/reference-logos/*",
          "/blog/",
          "/resources/",
          "/technology-logos/*",
          "/clients/",
          "/how-it-works/",
          "/eu-co-funded-projects/",
          "/top-navigation/",
          "/footer/",
          "/editor/",
        ],
        query: `
          {
            site {
              siteMetadata {
                siteUrl
              }
            }
            allSitePage {
              nodes {
                context {
                  story {
                    content
                  }
                }
                path
              }
            }
          }
        `,
        serialize: ({ site, allSitePage }) => {
          const siteMapEntries = []

          // PDF Pages
          ;[
            { url: "/cookies-policy.pdf", changefreq: "monthly" },
            { url: "/privacy-policy.pdf", changefreq: "monthly" },
            { url: "/security-policy.pdf", changefreq: "monthly" },
            { url: "/personal-data.pdf", changefreq: "monthly" },
            { url: "/marketing-consent.pdf", changefreq: "monthly" },
          ].forEach(pdfPage => {
            siteMapEntries.push({
              url: `${site.siteMetadata.siteUrl}${pdfPage.url}`,
              changefreq: pdfPage.changefreq,
              priority: 0.5,
            })
          })

          // Storyblok Pages
          allSitePage.nodes.forEach(node => {
            const content =
              node.context && node.context.story && node.context.story.content
                ? JSON.parse(node.context.story.content)
                : undefined

            const isNoIndex =
              content && content.seoMeta && content.seoMeta.noIndex
            const changefreq =
              content && content.seoMeta && content.seoMeta.changefreq
                ? content.seoMeta.changefreq
                : "daily"
            const priority =
              content && content.seoMeta && content.seoMeta.priority
                ? content.seoMeta.priority
                : 0.7

            if (!isNoIndex && node.path !== "/") {
              siteMapEntries.push({
                url: `${site.siteMetadata.siteUrl}${node.path}`,
                changefreq: changefreq,
                priority: Number(priority),
              })
            }
          })

          return siteMapEntries
        },
      },
    },
  ],
}
